import { CATTLE_DIRECTORY_STATUS } from "../../constants/app-constants";
import { SignedUrlParam } from "../models/animal/animal.models";
export const animalsQuerys = `animals{
    id
    tag
    media{
      of
      contentType
      url
    }
  }`;

const specificMediaOfAnimalsQuery = (mediaOf: string) => {
  return {
    query: `
    query{
      specificMediaOfAnimals(mediaOf:"${mediaOf}"){
        id
        tag
        media{
          of
          contentType
          url
        }
      }
    }`,
    variables: {}
  };
};

const getAnimalAppointmentDetails = (params: any) => {
  const { startTime, endTime, id, appointmentDate } = params;
  return {
    query: `query ($populateChild:Boolean,$tag:String, $id:String, $startTime: String!, $endTime: String!, $appointmentDate: String!){
 	                getSingleAnimal(populateChild:$populateChild,tag: $tag,id:$id){
                    id
                    farm{
                        id
                        code
                        name
                        visitTime{
                            startTime
                            endTime
                        }
                        milkingTimings{
                            morning{
                                startTime
                                endTime
                            }
                            evening{
                                startTime
                                endTime
                            }
                        }
                    }
                    appointmentDetails(startTime: $startTime, endTime: $endTime){
                        count{
                            morningMilking
                            eveningMilking
                            generic
                        }
                      }
                    proposedAppointmentTimeslot(appointmentDate: $appointmentDate){
                        morningMilking
                        eveningMilking
                    }
                  }
              }`,
    variables: {
      id: id,
      startTime: startTime,
      endTime: endTime,
      appointmentDate: appointmentDate
    },
  };
};

const animalByIdQuery = (param: any) => {
  const { startDate, endDate, qualityStartDate, id, populateChild } = param;
  return {
    query: `query SingleAnimalDetail($populateChild:Boolean,$startDate: String!,$endDate: String!,$qualityStartDate: String!,$id:String){
      getSingleAnimal(populateChild:$populateChild,id:$id){
          id
          tag
          animalType
          gender
          breed
          dob
          lactating
          lactationNo
          animalStatus
          healthStatus
          lastParturitionDate
          measurements{
              height{
                  ...quantity
              }
              weight{
                  ...quantity
              }
              widthFromBack{
                  ...quantity
              }
              midSectionLength{
                  ...quantity
              }
              volume{
                  ...quantity
              }
              bodyLength{
                  ...quantity
              }
              girth{
                  ...quantity
              }
          }
          media{
              of
              contentType
              url
              id
              mediaTags
          }
          parent{
              id
              tag
          }
          isChild
          children{
              id
              tag
              gender
              dob
              animalStatus
              healthStatus
              media{
                  of
                  contentType
                  url
              }
              farm{
                  id
                  code
                  name
              } 
              parent{
                id
                tag
            } 
          }
          farm{
              id
              code
              name
          }
          stayHistory{
              exitDateTime
              entryDateTime
          }
          averageMilkYield{
              ...milkYield
          }
          expectedMilkYield{
              ...milkYield
          }
          pricingDetails{
              mrp{
                  ...price
              }
              discountedPrice{
                  ...price
              }
          }
          milkYieldRecord(startDate: $startDate,endDate: $endDate){
              id
              weightUom
              weightValue
              sourceLocation
          }
          milkQualityRecord(startDate: $qualityStartDate,endDate: $endDate){
              fatPercentage
              snfPercentage
              sourceLocation
              densityUom
              densityValue
              waterPercentage
          }
          lastTransaction{
            sellerDetails{
                ...partyDetails
            } 
            transactionDate
        }
        beautyScore{
            horn
            udder
            body
            face
        }
        healthDetails{
            ...healthDetails
        }
      }
  }
  
  fragment milkYield on MilkYield{
      milkVolume{
          value
          uom
      }
      milkWeight{
          uom
          value
      }
  }
  fragment price on Price{
      amount
      currency
  }
  fragment quantity on Quantity{
      value
      uom
  }
  fragment partyDetails on PartyDetails{ 
      address{
          city 
      }
  }
  fragment healthDetails on HealthDetails{
    udder{
        teatComparision
        teatDefect
        teatGap
        teatLength
        teatPlacement
        teatRubberiness
        cleft
        leftMilkVienVisibility
        rightMilkVienVisibility
    }
    bodyVisibility{
        bcs
        sacralVisibility
        spineVisibility
        tailheadVisibility
    }
    vaccination{
        fmd
        blackQuarter
        hs
    }
    tests{
        brucella
        ketosis
        cmt
    }
}`,
    variables: {
      id: id,
      ...(populateChild && { populateChild: true }),
      startDate: startDate, endDate: endDate, qualityStartDate: qualityStartDate
    },
  };
};

const signedUrlQuery = ({
  animalId,
  mediaOf,
  mimeType,
  action = "resumable",
  mediaAction,
  mediaId='',
  mediaTags=undefined
}: SignedUrlParam) => {
  return {
    query: `
    query{
      getSignedUrl(
        animalId: "${animalId}",
        mediaOf: "${mediaOf}",
        mimeType: "${mimeType}",
        action: "${action}",
        mediaAction: "${mediaAction}",
        mediaId: "${mediaId}",
        mediaTags: ${mediaTags && JSON.stringify(mediaTags) || null}
      ){
        signedUrl
       }
    }`,
    variables: {},
  };
};

const getMilkRecordQuery = (
  startDate: any,
  endDate: any,
  currPage: number,
  rowsPerPage: number = 10
) => {
  const obj = `{ 
    recordId
    milkYieldFromAnimal
    calfDrinkFromTeat
    calfDrinkFromBottle
    milkingTime
  }`;
  return {
    query: `query ($queryInput:GetMilkingRecordByDateInput!){
      getMilkingRecordByDate (getMilkingRecordByDateInput:$queryInput){
        milkRecords{
          id
          tag
          evening ${obj}
          morning ${obj}
        }
        totalRecords
      }
    }`,
    variables: {
      queryInput: {
        startDate: startDate,
        endDate: endDate,
        currPage: currPage,
        rowsPerPage: rowsPerPage
      },
    },
  };
};

const getMilkingRecordByAnimalIdQuery = (
  animalId: any,
  startDate: any,
  endDate: any
) => {
  return {
    query: `query milkYieldBAnimalId($query: GetMilkingRecordByAnimalIdInput!){
      milkYieldRecordByAnimalId(getMilkingRecordByAnimalIdInput: $query){
        id
        milkingTime
        milkFromTeats
      }
  }`,
    variables: {
      query: {
        animalId: animalId,
        startDate: startDate,
        endDate: endDate
      },
    }
  };
};


const getFeedQuery = (date: any) => {
  const obj = `{
    id
    feedId
    createdAt
    changedAt
    weightValue
    weightUom
    sourceLocation
    recordTime
    remainingValue
  }`
  const time = `{
    roughage ${obj}
    silage ${obj}
    concentrate ${obj}
  }`
  return {
    query: `query($queryInput:GetFeedConsumptionsInput!) {
    getFeedConsumptions (getFeedConsumptionInput:$queryInput) {
      id
      tag
      morning ${time}
      evening ${time}
    }
  }`,
    variables: {
      queryInput: {
        date: date,
      },
    }
  }
}
const createFeedQueryMutation = (createFeed: any) => {
  return {
    query: `mutation($queryInput:[CreateFeedConsumptionsInput!]!) {
      createFeedConsumptions(records:$queryInput) {
        created
      }
    }`,
    variables: {
      queryInput: createFeed,
    },
  };
};
const updateFeedQueryMutation = (updateFeed: any) => {
  return {
    query: `mutation ($queryInput:[UpdateFeedConsumptionsInput!]!) {
      updateFeedConsumptions (records:$queryInput) {
        updated
      }
    }`,
    variables: {
      queryInput: updateFeed,
    },
  };
};
const getAnimalsQuery = (param: any) => {
  const { farmIds = [], media = "", daysFromLastParturition = [], lactationNos = [], partialTag = "", populateChild = true, take = 100, skipPage = 0, isSearch = false } = param || {};
  return {
    query: `query getAnimals($fetchAnimalInput: FetchAnimalInput!, $of: String!){
      getAnimals(fetchAnimalInput: $fetchAnimalInput){
           id
           status
           interestedBuyers
           tag
           animalType
           gender
           breed
           lactating
           dob
           lastParturitionDate
           lactationNo
           animalStatus
           healthStatus
           parent{
               id
               tag
           } 
           media(of: $of){
            of
            contentType
            url
          }
           isChild
           children{
               id
               tag
               isChild
               gender
               dob
               animalStatus
               healthStatus
              media(of: $of){
                of
                contentType
                url
              }
              parent{
                id
                tag
            } 
           }
           farm{ 
            id
            code
            name
          }
          stayHistory{
            entryDateTime
            exitDateTime
          }
          averageMilkYield{
              ...milkYield
          }
          expectedMilkYield{
              ...milkYield
          }
          pricingDetails{
              mrp{
                  ...price
              }
              discountedPrice{
                  ...price
              }
          }
       }
   }
   fragment milkYield on MilkYield{
    milkVolume{
        value
        uom
    }
    milkWeight{
        uom
        value
    }
}
fragment price on Price{
    amount
    currency
}`,
    variables: {
      // ...(farmId !== "ALL" && { filter: { farmId: farmId } }),
      "of": media,
      fetchAnimalInput: {
        "populateChild": populateChild,
        partialTag: partialTag,
        ...(lactationNos && lactationNos.length && { lactationNos: lactationNos }),
        ...(daysFromLastParturition && daysFromLastParturition.length && { daysFromLastParturition: daysFromLastParturition }),
        farmIds: farmIds,
        ...(!isSearch && { take: take , skip: skipPage })
      }
    },
  };
}

const updateFarmAnimalStatusMutation = (update: any, isChild = false) => {
  const isNotAlive = (CATTLE_DIRECTORY_STATUS.NOT_ALIVE === update.healthStatus && isChild);
  const isAnimalStatusUnderTreetMent = (CATTLE_DIRECTORY_STATUS.UNDER_TREATMENT === update.animalStatus);
  const isAnimalHealthStatusUnderTreetMent = (CATTLE_DIRECTORY_STATUS.UNDER_TREATMENT === update.healthStatus && !isChild);
  let animal, child;
  if ((!isChild && isAnimalHealthStatusUnderTreetMent && !isAnimalStatusUnderTreetMent)) {
    animal = `
    animalStatus
    healthStatus`
  }
  else if ((!isChild && isAnimalStatusUnderTreetMent && !isAnimalHealthStatusUnderTreetMent)) {
    animal = `healthStatus`
  }
  else if ((!isChild && update.animalStatus && !isAnimalHealthStatusUnderTreetMent && !isAnimalStatusUnderTreetMent)) {
    animal = `animalStatus`
  }
  else if ((update.isChild && update.healthStatus && !isNotAlive) && { healthStatus: update.healthStatus }) {
    child = `healthStatus`;
  }
  return {
    query: `mutation updateAnimal($updateAnimalInput: UpdateAnimalInput!,$id: String!){
      updateAnimal(updateAnimalInput: $updateAnimalInput, id : $id){ 
        id
         ${animal ? animal : ""}
         ${child ? child : ""}
      }
  }`,
    variables: {
      id: update.id,
      updateAnimalInput: {
        ...(isNotAlive && { alive: false }),
        ...((!isChild && isAnimalHealthStatusUnderTreetMent && !isAnimalStatusUnderTreetMent) && { animalStatus: update.animalStatus, healthStatus: CATTLE_DIRECTORY_STATUS.HEALTHY }),
        ...((!isChild && isAnimalStatusUnderTreetMent && !isAnimalHealthStatusUnderTreetMent) && { healthStatus: update.animalStatus }),
        ...((!isChild && update.animalStatus && !isAnimalHealthStatusUnderTreetMent && !isAnimalStatusUnderTreetMent) && { animalStatus: update.animalStatus }),
        ...((update.isChild && update.healthStatus && !isNotAlive) && { healthStatus: update.healthStatus }),
      }
    },
  };
};

const updateAnimalTagMutation = (id: any, tag: any, reason: string) => {
  return {
    query: `mutation changeAnimalTag($tag: String!,$id:String!,$reason:String!){
    changeAnimalTag(tag:$tag,id:$id,reason:$reason){
        id
        tag
    }
}`,
    variables: {
      id: id,
      tag: tag,
      reason: reason
    }
  }
}

const updateMediaTagMutation = (id: any, mediaId: any, mediaTags: any) => {
  return {
    query: `mutation updateMediaTag($mediaId: String!,$id:String!,$mediaTags:[String!]){
    updateMediaTag(mediaId:$mediaId,id:$id,mediaTags:$mediaTags){
        id
        mediaTags
    }
}`,
    variables: {
      id: id,
      mediaId: mediaId,
      mediaTags: mediaTags
    }
  }
}

const getAnimalMediaFromBazarQuery = (animalId: string) => {
  return {
    query: `query getAnimalMediaFromBazar($populateChild:Boolean,$id:String){
      getAnimalMediaFromBazar(populateChild:$populateChild,id:$id){
        id
        media{
          of
          contentType
          url  
        }
      }
}`,
    variables: {
      id: animalId,
    },
  };
};

const updateAnimalMediaInBazarMutation = (id: string, media: any) => {
  return {
    query: `mutation updateAnimalMediaInBazar($id:String!,$updateAnimalMediaInput:[UpdateAnimalMediaInput!]!){
    updateAnimalMediaInBazar(id:$id,updateAnimalMediaInput:$updateAnimalMediaInput){
        id
        media{
          of
          contentType
          url  
        }
    }
}`,
    variables: {
      id: id,
      updateAnimalMediaInput: media,
    }
  }
}

export const AnimalsQuery = {
  animalByIdQuery,
  specificMediaOfAnimalsQuery,
  signedUrlQuery,
  getMilkRecordQuery,
  getMilkingRecordByAnimalIdQuery,
  getFeedQuery,
  createFeedQueryMutation,
  updateFeedQueryMutation,
  getAnimalsQuery,
  updateFarmAnimalStatusMutation,
  updateAnimalTagMutation,
  getAnimalAppointmentDetails,
  updateMediaTagMutation,
  getAnimalMediaFromBazarQuery,
  updateAnimalMediaInBazarMutation
};
